import React from 'react';
import { useNavigate } from 'react-router-dom';

const MenuPage = () => {
  const navigate = useNavigate();

  return (
    <div className="container mt-4">
      <h2>Menu</h2>
      <button className="btn btn-primary mb-3" onClick={() => navigate('/tickets')}>
        Ticket List
      </button>
      <button className="btn btn-secondary mb-3 ml-2" onClick={() => navigate('/dashboard')}>
        Ticket Stats
      </button>
    </div>
  );
};

export default MenuPage;
