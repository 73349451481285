import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axiosInstance from './axiosConfig';  // Korrigiere den Pfad, nachdem du axiosConfig verschoben hast

function Login() {
  const [username, setUsername] = useState('');  // Zustand für den Benutzernamen
  const [password, setPassword] = useState('');  // Zustand für das Passwort
  const [errorMessage, setErrorMessage] = useState('');  // Zustand für Fehlermeldungen
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();
    try {
      const response = await axiosInstance.post('/login', {
        username,
        password,
      });
      if (response.status === 200) {
        navigate('/tickets');
      } else {
        setErrorMessage('Login failed');
      }
    } catch (error) {
      setErrorMessage('Login error');
    }
  };

  return (
    <div>
      <h2>Login</h2>
      <form onSubmit={handleLogin}>
        <input
          type="text"
          placeholder="Username"
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
        <input
          type="password"
          placeholder="Password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
        <button type="submit">Login</button>
      </form>
      {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
    </div>
  );
}

export default Login;
