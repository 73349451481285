import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosConfig';
import BackToMenuButton from './BackToMenuButton'; // Import the button
import './TicketStats.css';

import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Legend,
  PieChart,
  Pie,
  Cell,
  LineChart,
  Line,
  CartesianGrid
} from 'recharts';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import moment from 'moment';

const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042'];

// Helper function to group data by a key (e.g., 'techniker')
const groupBy = (array, key) => {
  return array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
    return result;
  }, {});
};

const TicketDashboard = () => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTechnician, setSelectedTechnician] = useState(null);
  const [filteredTechnicianTickets, setFilteredTechnicianTickets] = useState([]);

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await axiosInstance.get('/api/alltickets');
        setTickets(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching tickets:', error);
        setLoading(false);
      }
    };
    fetchTickets();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  // Group tickets by technician with status "new"
  const ticketsByTechnician = groupBy(
    tickets.filter(ticket => ticket.status === 'new'),
    'techniker'
  );

  const technicianData = Object.keys(ticketsByTechnician).map((tech) => ({
    name: tech,
    newTickets: ticketsByTechnician[tech].length,
  }));

  // Handle technician click on the chart
  const handleTechnicianClick = (tech) => {
    if (selectedTechnician === tech) {
      // If already selected, deselect (show all technicians)
      setSelectedTechnician(null);
      setFilteredTechnicianTickets([]);
    } else {
      // Show only tickets for selected technician
      setSelectedTechnician(tech);
      const filtered = ticketsByTechnician[tech].sort(
        (a, b) => moment(a.sla).diff(moment(b.sla)) // Sort by SLA
      );
      setFilteredTechnicianTickets(filtered);
    }
  };

  // Calculate remaining days for SLA and format table rows
  const renderSLARemainingDays = (sla) => {
    const today = moment().startOf('day');
    const slaDate = moment(sla).startOf('day');
    const diffDays = slaDate.diff(today, 'days');

    if (diffDays === 0) {
      return 'HEUTE';
    } else if (diffDays === 1) {
      return 'MORGEN';
    } else {
      return `in ${diffDays} Tagen`;
    }
  };

  // Custom tick component to rotate technician names vertically
  const CustomizedAxisTick = ({ x, y, payload }) => {
    return (
      <g transform={`translate(${x},${y})`}>
        <text x={0} y={0} dy={16} textAnchor="end" transform="rotate(-45)" fill="#666">
          {payload.value}
        </text>
      </g>
    );
  };

  const renderTechnicianTickets = () => {
    const ticketsToShow = selectedTechnician
      ? filteredTechnicianTickets
      : tickets.filter(ticket => ticket.status === 'new');

    return (
      <>
        <div>
          <BackToMenuButton /> {/* Add the X button */}
        </div>
        {selectedTechnician && <h4>Techniker: {selectedTechnician}</h4>} {/* Show selected technician */}
        <table className="table table-striped mt-3">
          <thead>
            <tr>
              <th style={{ width: '10%' }}>Status</th>
              <th>Ticket ID</th>
              <th>Titel</th>
              <th>SLA</th>
              <th>Restzeit bis SLA</th>
              <th>OTRS</th>
            </tr>
          </thead>
          <tbody>
            {ticketsToShow.map((ticket) => {
              const slaRemaining = renderSLARemainingDays(ticket.sla);
              const isToday = slaRemaining === 'HEUTE';
              return (
                <tr key={ticket.id} style={{ backgroundColor: isToday ? 'yellow' : 'inherit' }}>
                  <td>{slaRemaining}</td>
                  <td>{ticket.id}</td>
                  <td>{ticket.titel}</td>
                  <td>{ticket.sla}</td>
                  <td>{slaRemaining}</td>
                  <td>
                    <a
                      href={`https://service.danubix.com/otrs/index.pl?Action=AgentTicketZoom;TicketID=${ticket.id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="btn btn-primary btn-sm"
                    >
                      OTRS
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </>
    );
  };

  return (
    <div className="container mt-4">
      <h2>Ticket Dashboard</h2>

      <Tabs>
        <TabList>
          <Tab>Techniker-Tickets</Tab>
          <Tab>Tickets by Priority</Tab>
          <Tab>New Tickets by Queue</Tab>
        </TabList>

        {/* Tab for the Technician-Tickets Chart */}
        <TabPanel>
          <h4>Techniker-Tickets (Status: "new")</h4>
          <ResponsiveContainer width="100%" height={300}>
            <BarChart data={technicianData} onClick={(data) => handleTechnicianClick(data.activeLabel)}>
              <XAxis dataKey="name" tick={<CustomizedAxisTick />} interval={0} />
              <YAxis />
              <Tooltip />
              <Legend />
              <Bar
                dataKey="newTickets"
                fill="#8884d8"
                name="New Tickets"
                cursor="pointer"
              />
            </BarChart>
          </ResponsiveContainer>
          {renderTechnicianTickets()}
        </TabPanel>

        {/* Other tabs for additional charts */}
        <TabPanel>
          <h4>Tickets by Priority</h4>
          <ResponsiveContainer width="100%" height={300}>
            <PieChart>
              <Pie
                dataKey="value"
                data={[
                  { name: 'High', value: 10 },
                  { name: 'Medium', value: 20 },
                  { name: 'Low', value: 30 },
                ]}
                cx="50%"
                cy="50%"
                outerRadius={80}
                fill="#8884d8"
                label
              />
              {COLORS.map((color, index) => (
                <Cell key={`cell-${index}`} fill={color} />
              ))}
            </PieChart>
          </ResponsiveContainer>
        </TabPanel>

        <TabPanel>
          <h4>New Tickets by Queue</h4>
          <ResponsiveContainer width="100%" height={300}>
            <LineChart data={technicianData}>
              <XAxis dataKey="name" />
              <YAxis />
              <Tooltip />
              <CartesianGrid stroke="#eee" strokeDasharray="5 5" />
              <Line type="monotone" dataKey="newTickets" stroke="#8884d8" />
            </LineChart>
          </ResponsiveContainer>
        </TabPanel>
      </Tabs>
    </div>  
  );
};

export default TicketDashboard;
