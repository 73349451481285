// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.recharts-responsive-container{
  height: 500px !important;
}
.recharts-wrapper{
  max-height: 500px !important;
}



`, "",{"version":3,"sources":["webpack://./src/TicketStats.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;AACA;EACE,4BAA4B;AAC9B","sourcesContent":[".recharts-responsive-container{\n  height: 500px !important;\n}\n.recharts-wrapper{\n  max-height: 500px !important;\n}\n\n\n\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
