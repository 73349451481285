import React, { useState, useEffect } from 'react';
import axiosInstance from './axiosConfig';  // Verwende die konfigurierte Axios-Instanz

const groupBy = (array, key) => {
  return array.reduce((result, currentValue) => {
    (result[currentValue[key]] = result[currentValue[key]] || []).push(currentValue);
    return result;
  }, {});
};

const TechnicianTickets = () => {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await axiosInstance.get('/api/alltickets');
        setTickets(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Fehler beim Abrufen der Tickets:', error);
        setLoading(false);
      }
    };
    fetchTickets();
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  // Filter "new" tickets by technician
  const ticketsByTechnician = groupBy(tickets.filter(ticket => ticket.status === 'new'), 'techniker');

  return (
    <div className="container mt-4">
      <h2>Techniker Übersicht (Tickets mit Status "new")</h2>
      {Object.keys(ticketsByTechnician).map(tech => (
        <div key={tech} className="mb-4">
          <h4>{tech}</h4>
          <ul>
            {ticketsByTechnician[tech].map(ticket => (
              <li key={ticket.id}>
                {ticket.titel} - {ticket.queue} - {ticket.customer}
              </li>
            ))}
          </ul>
        </div>
      ))}
    </div>
  );
};

export default TechnicianTickets;

