import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Login from './Login';
import TicketList from './TicketList';
import 'bootstrap/dist/css/bootstrap.min.css';
import TicketStats from './TicketStats';  // Neue Dashboard-Komponente
import TechnicianTickets from './TechnicianTickets';  // Import der neuen interaktiven Ansicht
import Menu from './Menu';
import TicketMap from './TicketMap';

function App() {
  return (
    <Router>
      <Routes>

        <Route path="/" element={<Menu />} /> {/* Default route to menu */}

        <Route path="/login" element={<Login />} />
        <Route path="/tickets" element={<TicketList />} />
        <Route path="/stats" element={<TicketStats />} />  {/* Neue Route für das Dashboard */}
        <Route path="/technicians" element={<TechnicianTickets />} />  {/* Neue interaktive Ansicht */}
	        <Route path="/map" element={<TicketMap />} />
      </Routes>
    </Router>
  );
}

export default App;

