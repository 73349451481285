import React, { useEffect, useState, useCallback, useRef } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap, useMapEvent } from 'react-leaflet';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css';
import 'leaflet-routing-machine';
import axiosInstance from './axiosConfig';
import markerShadow from 'leaflet/dist/images/marker-shadow.png';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import './TicketsMap.css';

// Fix for Leaflet's default icon not being loaded correctly
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
  shadowUrl: markerShadow,
});

// Farben basierend auf den Hauptqueues
const queueColors = {
  'OELG': '#00b894',
  'Rowius': '#74b9ff',
  'Offen': '#a29bfe',
  'ASFINAG': '#dfe6e9',
  'Majer Computer': '#636e72',
  'Post VOS': '#ff7675',
  'FTS_Repair': '#d63031',
  'T-Systems Combridge': '#81ecec',
  'SoloIT-REWE': '#fdcb6e',
  'HIT': '#0984e3',
};

// Funktion zur Zuordnung der Farben basierend auf Hauptqueues
const getMainQueueColor = (queue) => {
  const mainQueue = queue.split('::')[0];
  return queueColors[mainQueue] || '#000000';
};

// Funktion zur Berechnung der Restzeit in hh:mm
const calculateRemainingTime = (slaTime) => {
  const now = new Date();
  const slaDate = new Date(slaTime);
  const diff = slaDate - now;
  const hours = Math.floor(Math.abs(diff) / (1000 * 60 * 60));
  const minutes = Math.floor((Math.abs(diff) % (1000 * 60 * 60)) / (1000 * 60));
  const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}`;
  return diff < 0 ? `-${formattedTime}` : formattedTime;
};

function TicketsMap() {
  const [tickets, setTickets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selectedTickets, setSelectedTickets] = useState([]);
  const [filteredQueues, setFilteredQueues] = useState(['OELG', 'Rowius', 'Post VOS', 'T-Systems Combridge', 'SoloIT-REWE']);
  const [currentZoom, setCurrentZoom] = useState(7);
  const [routingControl, setRoutingControl] = useState(null);
  const [routeMarkers, setRouteMarkers] = useState([]);
  const [routeTime, setRouteTime] = useState('');
  const mapRef = useRef();

  useEffect(() => {
    const fetchTickets = async () => {
      try {
        const response = await axiosInstance.get('/api/tickets');
        setTickets(response.data);
        setLoading(false);
      } catch (error) {
        console.error('Fehler beim Laden der Tickets:', error);
        setLoading(false);
      }
    };
    fetchTickets();

    // Aktualisiere die SLA-Zeiten jede Minute
    const interval = setInterval(fetchTickets, 60000);
    return () => clearInterval(interval);
  }, []);

  const groupTicketsByLocation = () => {
    const grouped = {};
    tickets.forEach(ticket => {
      const key = `${ticket.latitude},${ticket.longitude}`;
      if (!grouped[key]) {
        grouped[key] = [];
      }
      grouped[key].push(ticket);
    });
    return grouped;
  };

  const groupedTickets = groupTicketsByLocation();

  const handleTicketSelect = useCallback((ticket) => {
    setSelectedTickets(prevTickets => {
      if (prevTickets.some(t => t.id === ticket.id)) {
        return prevTickets;
      }
      return [...prevTickets, ticket];
    });
  }, []);

  const handleTicketClose = (ticketId) => {
    setSelectedTickets(prevTickets => prevTickets.filter(ticket => ticket.id !== ticketId));
  };

  const createMarkerIcon = (ticketsAtLocation, zoomLevel) => {
    const count = ticketsAtLocation.length;
    const markerSize = Math.max(8 + (zoomLevel * 1.5), 10);
    const fontSize = Math.max(8 + (zoomLevel - 7), 10);
    const technicianFontSize = fontSize - 2;

    // Farbe und Rahmen abhängig von der Anzahl der Tickets
    const markerColor = count > 1 ? 'black' : getMainQueueColor(ticketsAtLocation[0].queue);
    const technician = ticketsAtLocation[0].techniker;
    const borderColor = technician === 'OTRS Admin' ? 'transparent' : 'black';

    // Anzeige der Restzeit und des Technikers auf dem Marker
    const remainingTime = calculateRemainingTime(ticketsAtLocation[0].sla);
    const markerContent = `
      <div style="background-color: ${markerColor}; border: 2px solid ${borderColor}; border-radius: 50%; width: ${markerSize}px; height: ${markerSize}px; display: flex; align-items: center; justify-content: center; flex-direction: column; color: black;">
        <span style="font-weight: bold; font-size: ${fontSize}px;">${remainingTime}</span><br/>
        <span style="font-size: ${technicianFontSize}px;">(${technician})</span>
      </div>
    `;

    return L.divIcon({
      html: markerContent,
      className: 'custom-marker',
      iconSize: [markerSize, markerSize],
    });
  };

  const renderPopupContent = (ticketsAtLocation) => {
    return (
      <div>
        <h4>{ticketsAtLocation.length} Tickets an dieser Position</h4>
        <ul>
          {ticketsAtLocation.map((ticket) => (
            <li key={ticket.id}>
              <strong>{ticket.titel}</strong> <br />
              Queue: <span style={{ backgroundColor: getMainQueueColor(ticket.queue), padding: '2px 4px', borderRadius: '3px' }}>{ticket.queue}</span> <br />
              Techniker: {ticket.techniker} <br />
              SLA: {ticket.sla} <br />
              Restzeit: {calculateRemainingTime(ticket.sla)} <br />
              Adresse: {ticket.address}, PLZ: {ticket.plz} <br />
              <button onClick={() => handleTicketSelect(ticket)}>Details anzeigen</button>
              <br />
              <button onClick={() => addRouteMarker(ticket.latitude, ticket.longitude)}>Als Marker hinzufügen</button>
              <br />
              <a
                href={`https://service.danubix.com/otrs/index.pl?Action=AgentTicketZoom;TicketID=${ticket.id}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ color: 'black', textDecoration: 'underline' }}
              >
                OTRS öffnen
              </a>
            </li>
          ))}
        </ul>
      </div>
    );
  };

  const handleMarkerClick = (ticketsAtLocation) => {
    if (ticketsAtLocation.length === 1) {
      handleTicketSelect(ticketsAtLocation[0]);
    }
  };

  const handleQueueFilterChange = (queue) => {
    if (filteredQueues.includes(queue)) {
      setFilteredQueues(filteredQueues.filter(q => q !== queue));
    } else {
      setFilteredQueues([...filteredQueues, queue]);
    }
  };

  const MapClickHandler = () => {
    useMapEvent('click', (e) => {
      if (e.originalEvent.ctrlKey) {
        addRouteMarker(e.latlng.lat, e.latlng.lng);
      }
    });
    return null;
  };

  const addRouteMarker = (lat, lng) => {
    setRouteMarkers(prevMarkers => [
      ...prevMarkers,
      { lat, lng, name: `Marker${prevMarkers.length + 1}` }
    ]);
    calculateRoute(); // Routenberechnung direkt nach Hinzufügen eines Markers
  };

  const removeRouteMarker = (index) => {
    setRouteMarkers(prevMarkers => prevMarkers.filter((_, i) => i !== index));
    calculateRoute(); // Routenberechnung nach Entfernen eines Markers
  };

  const calculateRoute = useCallback(() => {
    if (routingControl) {
      routingControl.remove(); // Vorherige Route entfernen
    }

    if (routeMarkers.length > 1 && mapRef.current) {
      const waypoints = routeMarkers.map(marker => L.latLng(marker.lat, marker.lng));

      const newRoutingControl = L.Routing.control({
        waypoints,
        routeWhileDragging: false,
        show: false,
        createMarker: () => null,
      }).addTo(mapRef.current);

      newRoutingControl.on('routesfound', function(e) {
        const route = e.routes[0];
        const time = route.summary.totalTime;
        const hours = Math.floor(time / 3600);
        const minutes = Math.floor((time % 3600) / 60);
        setRouteTime(`${hours} Stunden ${minutes} Minuten`);
      });

      setRoutingControl(newRoutingControl);
    }
  }, [routeMarkers]);

  const filteredTickets = tickets.filter(ticket => {
    const mainQueue = ticket.queue.split('::')[0];
    return filteredQueues.includes(mainQueue);
  });

  const groupedFilteredTickets = filteredTickets.reduce((acc, ticket) => {
    const key = `${ticket.latitude},${ticket.longitude}`;
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(ticket);
    return acc;
  }, {});

  return (
    <div style={{ width: '100%', height: '100vh', position: 'relative' }}>
      {loading ? (
        <div className="text-center">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      ) : (
        <>
          <MapContainer
            center={[47.5162, 14.5501]}
            zoom={currentZoom}
            style={{ height: '100%', width: '100%' }}
            ref={mapRef}
          >
            <TileLayer
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
              attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            />
            <MapClickHandler />
            {Object.keys(groupedFilteredTickets).map((key, index) => {
              const [lat, lng] = key.split(',').map(Number);
              const ticketsAtLocation = groupedFilteredTickets[key];
              const ticketCount = ticketsAtLocation.length;

              return (
                <Marker
                  key={index}
                  position={[lat, lng]}
                  icon={createMarkerIcon(ticketsAtLocation, currentZoom)}
                  eventHandlers={{
                    click: () => handleMarkerClick(ticketsAtLocation),
                  }}
                >
                  {ticketCount > 1 && (
                    <Popup>{renderPopupContent(ticketsAtLocation)}</Popup>
                  )}
                </Marker>
              );
            })}
            {routeMarkers.map((marker, index) => (
              <Marker key={index} position={[marker.lat, marker.lng]}>
                <Popup>
                  {marker.name}
                  <button onClick={() => removeRouteMarker(index)}>X</button>
                </Popup>
              </Marker>
            ))}
          </MapContainer>
          <div style={{ position: 'absolute', top: '10px', left: '10px', backgroundColor: 'white', padding: '10px', borderRadius: '5px', boxShadow: '0px 0px 10px rgba(0,0,0,0.2)', zIndex: 1000, width: '300px' }}>
            <h5>Queues</h5>
            <ul>
              {Object.keys(queueColors).map((queue) => (
                <li key={queue} style={{ cursor: 'pointer', opacity: filteredQueues.includes(queue) ? 1 : 0.5 }} onClick={() => handleQueueFilterChange(queue)}>
                  <span style={{ backgroundColor: queueColors[queue], padding: '2px 4px', borderRadius: '3px', display: 'inline-block', width: '20px', height: '20px', marginRight: '5px' }}></span>
                  {queue}
                </li>
              ))}
            </ul>
            <button onClick={() => setFilteredQueues(Object.keys(queueColors))}>Alle anzeigen</button>
            <button onClick={() => setFilteredQueues([])}>Alle ausblenden</button>
            {selectedTickets.length > 0 && (
              <DragDropContext onDragEnd={() => { /* Drag and drop logic here */ }}>
                <Droppable droppableId="ticket-list">
                  {(provided) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      style={{
                        marginTop: '20px',
                        backgroundColor: 'white',
                        padding: '10px',
                        borderRadius: '5px',
                        boxShadow: '0px 0px 10px rgba(0,0,0,0.2)',
                        maxHeight: '60vh',
                        overflowY: 'auto'
                      }}
                    >
                      {selectedTickets.map((ticket, index) => (
                        <Draggable key={ticket.id} draggableId={ticket.id.toString()} index={index}>
                          {(provided) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              style={{
                                ...provided.draggableProps.style,
                                marginBottom: '10px',
                                borderBottom: '1px solid #ddd',
                                paddingBottom: '10px'
                              }}
                            >
                              <button style={{ float: 'right' }} onClick={() => handleTicketClose(ticket.id)}>X</button>
                              <p><strong>{ticket.titel}</strong> <span style={{ backgroundColor: getMainQueueColor(ticket.queue), padding: '2px 4px', borderRadius: '3px' }}>{ticket.queue}</span></p>
                              <p>Techniker: {ticket.techniker}</p>
                              <small>{ticket.address}, {ticket.plz}</small>
                              <br />
                              <strong>SLA:</strong> {ticket.sla}
                              <br />
                              <button onClick={() => addRouteMarker(ticket.latitude, ticket.longitude)}>Als Marker hinzufügen</button>
                              <br />
                              <a
                                href={`https://service.danubix.com/otrs/index.pl?Action=AgentTicketZoom;TicketID=${ticket.id}`}
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ color: 'black', textDecoration: 'underline' }}
                              >
                                OTRS öffnen
                              </a>
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>
            )}
          </div>
          <div style={{ position: 'absolute', top: '10px', right: '10px', backgroundColor: 'white', padding: '10px', borderRadius: '5px', boxShadow: '0px 0px 10px rgba(0,0,0,0.2)', zIndex: 1000, width: '200px' }}>
            <h5>Routenplaner</h5>
            {routeTime && <p>Fahrzeit: {routeTime}</p>}
            <ul>
              {routeMarkers.map((marker, index) => (
                <li key={index}>
                  {marker.name}
                  <button onClick={() => removeRouteMarker(index)} style={{ marginLeft: '10px' }}>X</button>
                </li>
              ))}
            </ul>
          </div>
        </>
      )}
    </div>
  );
}

export default TicketsMap;
